import React, { useState } from 'react';
import axios from 'axios';
import {useHistory,NavLink } from 'react-router-dom';
import config from '../config.json';

export default function Consent() {

  const [signature, setSignature] = useState();
  const [msg,setMsg] = useState('');
  let history = useHistory();
  const API_URL = config.API_URL;

  const tokenString = sessionStorage.getItem('token');
  const location_id = sessionStorage.getItem('location_id');
  const service_id  = sessionStorage.getItem('service_id'); 
  const firstname  = sessionStorage.getItem('firstname'); 

  console.log(firstname);

  var bkurl         = "/appointment/"+service_id+"/"+location_id; 
  const userToken   = JSON.parse(tokenString);
  const customer_id = userToken?.token;




  const onFailure = error => {
    console.log('---------------Error---------');
    console.log('inside onfailure',error);  
  
  };



  
  async function agreement(credentials) {
  
       
    var url =API_URL+'/newaccount/customeragreement';
    //const response  = await APIKit.get(endpoint);
    //  console.log(response)
    // const data = await JSON.parse(response.request._response);
    return axios.post(url, credentials)
   // .then(onSuccess)
   // .catch(onFailure);
   .then(request => request.data)
   .catch(onFailure);
    
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setMsg('');
    console.log('handle sumbit');


   

    let str = firstname.toUpperCase();//"Example String!";
    let ourSubstring = signature.toUpperCase();//"Example";

    if(str.indexOf(ourSubstring)===-1){
     
     setMsg('Please Make sure that your first name is in the signature');
     return false;
    }
    
    


   
    const results = await 
    agreement({
       
        'customer_id':customer_id,
        'agreement_signature':signature,
       
  
      });

   console.log(results.data);
   var url="/payment";
   history.push(url);
   

   
  }
  return(
    <>
     <div>
        <div className="viewport">
           
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done">
                        <div className="box box--md box--solo"><NavLink className="back box__back" to={bkurl}>Back to Timings</NavLink>
                           
                            <div className="loader--async" id="agreement-container">
                                <form className="agr_form" id="agr_wrap" onSubmit={handleSubmit}>
                                    <h1 className="agr_title">Registration Consent</h1>
                                    <div className="agr_body">
                                        <p></p>
                                        <p><span>Please review and agree with each of the following terms. Remember, you can only proceed to the next
steps when all toggle buttons are turned blue.</span></p>
                                        <p></p>
                                    </div>
                                    <ul className="agr_fields">
                                        <li className=" agr_cbox"><input id="VPa8f7588ece4f418d9799339962d63ae8" name="VPa8f7588ece4f418d9799339962d63ae8" required type="checkbox" value="true" role="switch"/><label htmlFor="VPa8f7588ece4f418d9799339962d63ae8">I understand that the assessment is only available in English at this location.</label></li>
                                        <li className=" agr_cbox"><input id="VP4a32f4bce8754e43acbe02231c6611b8" name="VP4a32f4bce8754e43acbe02231c6611b8" required type="checkbox" value="true" role="switch"/><label htmlFor="VP4a32f4bce8754e43acbe02231c6611b8">I certify that I am at least 16 years old</label></li>
                                        <li className=" agr_cbox"><input id="VPd685abbf512240a191bacf09315bd4ee" name="VPd685abbf512240a191bacf09315bd4ee" required type="checkbox" value="true" role="switch"/><label htmlFor="VPd685abbf512240a191bacf09315bd4ee">I understand in order to obtain a DC DMV Learner Permit, I must pass the knowledge test and vision screening, and provide documentation that proves my identity, residency, and eligibility</label></li>
                                        <li className=" agr_cbox"><input id="VP46fd0247ca6e49c4a852b71c4a0fcea5" name="VP46fd0247ca6e49c4a852b71c4a0fcea5" required type="checkbox" value="true" role="switch"/><label htmlFor="VP46fd0247ca6e49c4a852b71c4a0fcea5">I will show photo ID at the start of the exam and observe appropriate conduct through the test</label></li>
                                        <li className=" agr_cbox"><input id="VPef1190030eb242bbb77af53131d64c12" name="VPef1190030eb242bbb77af53131d64c12" required type="checkbox" value="true" role="switch"/><label htmlFor="VPef1190030eb242bbb77af53131d64c12">During the exam, I will observe appropriate test procedures; I will not use books or notes, not use a cell phones or electronic devices, not access the Internet or pagers, and not contact anyone or obtain assistance</label></li>
                                        <li className=" agr_cbox"><input id="VP4c2e7be4a2ee42a49ff2dfa71fe0e8ec" name="VP4c2e7be4a2ee42a49ff2dfa71fe0e8ec" required type="checkbox" value="true" role="switch"/><label htmlFor="VP4c2e7be4a2ee42a49ff2dfa71fe0e8ec">I understand that I am responsible for any proctoring fees and/or costs related to appointment scheduling, testing, and cancellation</label></li>
                                        <li className=" agr_cbox"><input id="VP2921451871c048a8a844ecff63abf64b" name="VP2921451871c048a8a844ecff63abf64b" required type="checkbox" value="true" role="switch"/><label htmlFor="VP2921451871c048a8a844ecff63abf64b">I understand that submitting falsified information will lead to cancellation of any pending test results and may prevent me from obtaining a DC license</label></li>
                                        <li className=" agr_cbox"><input id="VPf0f37c09d07243b8b300ede31caafcb1" name="VPf0f37c09d07243b8b300ede31caafcb1" required type="checkbox" value="true" role="switch"/><label htmlFor="VPf0f37c09d07243b8b300ede31caafcb1">I certify that I have not taken the DC DMV Knowledge Test in the past 3 calendar days from the date I am currently scheduling for</label></li>
                                        <li className=" agr_cbox"><input id="VPb705d5e70d5a4edbb75297af87f747ec" name="VPb705d5e70d5a4edbb75297af87f747ec" required type="checkbox" value="true" role="switch"/><label htmlFor="VPb705d5e70d5a4edbb75297af87f747ec">I understand that if it is determined that I have not complied with the 3 calendar day waiting period, any associated test results will become null and void. Re-testing will be required and no refunds will be issued</label></li>
                                        <li className=" agr_cbox"><input id="VP39dd38de04c44e6889aef5abced6fe52" name="VP39dd38de04c44e6889aef5abced6fe52" required type="checkbox" value="true" role="switch"/><label htmlFor="VP39dd38de04c44e6889aef5abced6fe52">I understand that if I do not show up for the test I am scheduling, I will not be eligible for any refund.</label></li>
                                        <li><label htmlFor="VP035b2eea3bf3468386ef87bb7d3f32a9">By typing your name below, you verify that you have read, understand, and agree to the guidelines outlined above and all information provided is true and correct</label>
                                        <input  name="agreement_signature" required type="text" onChange={e => setSignature(e.target.value)}/></li>
                                        {msg!==''?<div className='alert-error' style={{marginTop:10}}><div className="card-box">{msg}</div></div>:null}
                                        
                                    </ul>
                                      <button type="submit" className="agr_btn">Submit</button>
                                </form>
                               
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </div>
    </div>
    </>
  );
}