import React, { useState, useEffect } from "react";
import {useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import '../stripe.css';
import config from '../config.json';


export default function Payment() {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [holdername, setHoldername] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const API_URL  = config.API_URL;
  let appoint_id = '0';
  
  let appointment = sessionStorage.getItem('appointment');
  appointment = JSON.parse(appointment);
   
  //console.log('appointment------->>>',appointment);



  let history = useHistory();


 
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads

    let url=API_URL+"/service/cardpayment"; 

   

    
    
    window
      .fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
       // body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
        body: JSON.stringify({"amount":"1000","currency":"usd"})
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
      //  console.log(data.data.clientSecret);
        setClientSecret(data.data.clientSecret);
      });
  }, []);
  
  const onFailure = error => {
    console.log('---------------Error---------');
    console.log('inside onfailure',error);  
  
  };


  

  async function book() {
  
       
  
      let postData =   appointment;

          console.log('book func',postData);
     
         const results = await bookSlot(postData);
          console.log(results);
          if(results.success)
          {
           console.log('booking done on server');
           appoint_id = results.data.appointment.appointment_id;

           await sessionStorage.setItem('appointment_location',results.data.location.location_name);

           
           return true;
           
      
          }else{
              console.log(results.message);
             // setClassname('alert-error');
              //setError(results.message);
              setError('Your payment is not processed because '+results.message);
              return false;
          }

     
      
    }

  async function bookSlot(postData) {
  
       
      var url =API_URL+'/booking/book';
            
      return axios.post(url, postData)
      .then(request => request.data)
      .catch(onFailure);

     
      
  }


 

  
  async function UpdatPayment(credentials) {
  
       console.log(credentials);
    
    var url =API_URL+'/service/apptpayment';
    //const response  = await APIKit.get(endpoint);
    //  console.log(response)
    // const data = await JSON.parse(response.request._response);
    return axios.post(url, credentials)
   // .then(onSuccess)
   // .catch(onFailure);
   .then(request => request.data)
   .catch(onFailure);
    
  }

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const UTCTimestamp=()=>
  {
    var d1 = new Date();
    d1.toUTCString(); 
    return Math.floor(d1.getTime()/ 1000);
  }
  
  
  
  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    setError("");

    
    if(holdername==null || holdername==="")
    {
      console.log('no name');
     
      setProcessing(false);
      setError("Please provide card holder name");
      return;
    }

   
   const booking = await book();
   if(!booking)
   {
   
    
    
    setProcessing(false);
      return;
   }
   
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
            name: holdername,
          },
      }
    });
    console.log(payload);

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      console.log(appoint_id);
      
      const results = await 
      UpdatPayment({
        "appointment_id": appoint_id,
        "created_at":  UTCTimestamp(),
        "currency": "USD",
        "payment_method":  "card",
        "total" :  "1000",
        "txn_id":  payload.paymentIntent.id
  
      });
     
     console.log(results);
     if(results && results.success)
     {
       console.log('Payment updated to the server',"go to success page");
       history.push("/dashboard");
     }

      

    }
  };

  const back = ()=>{
    console.log('sfsdf');
    history.push("/consent");
  }
  return (


        <div>
        <div className="viewport">
            
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done" tabIndex="-1" aria-labelledby="page-title">
                        <div className="box box--md box--solo"><div className="back box__back"  onClick={back}>Back to Consent</div>
                            <h1 className="box__title">Payment Details</h1>
                            <p>You will be charged a NON-REFUNDABLE amount of $10 per test.</p>
                            <form id="payment-form" className="form payment" onSubmit={handleSubmit}>
                            
     <input
        type="text"
        value={holdername}
        onChange={(e) => setHoldername(e.target.value)}
        placeholder="Card Holder Name"
      />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay and schedule"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" style={{paddingTop:10}} role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded
      </p>
    </form>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
  );
}