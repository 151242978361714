import React, { useState  } from 'react';
import axios from 'axios';
import {useHistory,NavLink } from 'react-router-dom';
import DatePicker from "react-datepicker";
import '../datepicker.css';
import config from '../config.json';






export default function Appointment(props) {

    //'http://getbuchung.com/signup/newapi/api/web/v1/checkavailbility/dayslots

    //const location_id1 = new URLSearchParams(window.location.search).get('location_id');
    //const service1 = new URLSearchParams(window.location.search).get('service');
    const [responseData, setResponseData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [availabledate, setAvailabledate] = useState(new Date());
    const [error, setError] = useState();
    const [classname, setClassname] = useState();
    const [loading, setLoading] = useState(false);

    const { service } = props.match.params;
    const { location_id } = props.match.params;
    console.log('---',service,location_id);
    const API_URL = config.API_URL; 
    let history = useHistory();
    const tokenString = sessionStorage.getItem('token');
    const userToken   = JSON.parse(tokenString);
    const customer_id = userToken?.token;
  
   
   
  
    const onFailure = error => {
      console.log('---------------Error---------');
      console.log('inside onfailure',error);  
      setResponseData([]);
      setClassname('alert-error');
      setError('No Data Found');
      setLoading(false);
    
    };
  
    async function book(start,end) {
    
         
       
        let postData =  {     
            "customer_id":customer_id ,
            "service_id":service ,
            "location_id":location_id ,
            "date":formatDate(availabledate) ,
            "start_time":start,
            "end_time":end,
            "payment_status":"unpaid"
             };

            
             const appointment = JSON.stringify(postData);
            
             console.log('book func',appointment);
             await sessionStorage.setItem('appointment', appointment);

            const tokenString = sessionStorage.getItem('appointment');
            const userToken = JSON.parse(tokenString);
            console.log(userToken);
            history.push('/consent');
       
         /*  const results = await bookSlot(postData);
            console.log(results);
            if(results.success)
            {
             console.log('booking done on server');
             
             sessionStorage.setItem('appointment_id', results.data.appointment.appointment_id);

            console.log('appointment------->>>'+sessionStorage.getItem('appointment_id'));
             history.push('/info');
            }else{
                console.log(results.message);
                setClassname('alert-error');
                setError(results.message);
            }

       */
        
      }
  
   /* async function bookSlot(postData) {
    
         
        var url =API_URL+'/booking/book';
              
        return axios.post(url, postData)
        .then(request => request.data)
        .catch(onFailure);

       
        
    }
    */
    
    async function loginUser1(credentials) {
    
        console.log(credentials);
        setError(); 
      var url =API_URL+'/checkavailbility/dayslots';
      //const response  = await APIKit.get(endpoint);
      //  console.log(response)
      // const data = await JSON.parse(response.request._response);
      return axios.post(url, credentials)
     // .then(onSuccess)
     // .catch(onFailure);
     .then(request => request.data)
     .catch(onFailure);
      
    }

    function fulldate(date){
        
        var month = date.toLocaleString('en-us', { month: 'long' });
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var dayName = days[date.getDay()];
        return dayName +', ' + month +' '+date.getDate().toString(); 
        
      //  var year = date.getFullYear();

        //var month = (1 + date.getMonth()).toString();
        //month = month.length > 1 ? month : '0' + month;
      
        //var day = date.getDate().toString();
        //day = day.length > 1 ? day : '0' + day;
        
        //return month + '/' + day + '/' + year;
    
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
     
    
    const handleSubmit = async e => {
        e.preventDefault();
    
       

        console.log('handle sumbit',date);
        console.log(formatDate(date));
        setLoading(true);
        setResponseData([]);
       // const token = await 
     
      const  response= await loginUser1({
          'date':formatDate(date),
          'location_id':location_id,
          'service_id':service
          
        });
    
        
        if(response)
        {
            console.log(response);
            if(response.success)
            {
                setResponseData(response.data.availabletimes);
                setAvailabledate(date);
                console.log(response.data.availabletimes);
            }else{
                
                
                setClassname('alert-error');
                setError(response.message);

            }
        }
      
       setLoading(false);
       
      }


      const getslots =async (date) => {
       
    
        setDate(date)

        console.log('handle sumbit',date);
        console.log(formatDate(date));
        setLoading(true);
        setResponseData([]);
       // const token = await 
     
      const  response= await loginUser1({
          'date':formatDate(date),
          'location_id':location_id,
          'service_id':service
          
        });
    
        
        if(response)
        {
            console.log(response);
            if(response.success)
            {
                setResponseData(response.data.availabletimes);
                setAvailabledate(date);
                console.log(response.data.availabletimes);
            }else{
                
                
                setClassname('alert-error');
                setError(response.message);

            }
        }
      
       setLoading(false);
       
      }
    /*  
      const back = ()=>{
        console.log('back');
        history.goBack();
      }
*/

  return(
      <>
    
    <div>
        <div className="viewport">
            
            <div id="main" className="content">
                <div>
                    <main className="page wrap">
                        <div className="box box--md box--solo"> 
                        <NavLink className="back box__back" to="/tests">Back to Tests</NavLink>
                        
                            <h1 className="box__title">Select an Appointment Date</h1>
                            <p>Please select an appointment date from the calendar below.</p>
                            <form className="form form--inline" onSubmit={handleSubmit}>
                                <fieldset>
                                    <ul className="form__fields">
                                        <li className="mt-5">
                                        <DatePicker 
                                            selected={date}
                                           // onChange={(date) => setDate(date)}
                                            onChange={(date) => getslots(date)}
                                            calendarClassName={"red-border"}
                                            minDate={new Date()}
                                           // includeDates={[new Date(),new Date('2021-05-11')]}
                                           
                                            inline

                                        />
                                        


                                        </li>
                                       
                                    </ul>
                                   
                                </fieldset>

                            </form>
                            {loading ? <div><img src={config.BASE_URL+"/img/loader.gif"} alt="loading.."/></div>:''}
                            {error ? <div className={classname}><label className="form__lbl" htmlFor="geolocate-zip">{error}</label></div>:''}

                            <div className="timeslots-wrap"  aria-labelledby="slots-heading">
                                
                                <div>
                                    <section className="box__section">
                                        <h5 className="box__subhead mb-5 mt-7">Available times</h5>
                                        <ul className="timeslots">
                                        
                                            
                                        {
                                responseData.map((item,index)=>{
                                   // let url ="/zipcode?id="+item.service_id+"&price="+item.service_price;
                                    //let link ="/zipcode/"+item.service_id+"/"+item.service_price;
                                return( 
                                    <li key={index}>
                                        <h6  className="timeslots__time">{item.start} - {item.end}<span className="timeslots__date">{fulldate(availabledate)}</span></h6><button className="btn btn--outline" onClick={() => book(item.start,item.end) }  >Schedule</button>
                                    </li>
                                );
                               
                                })
                            }
                                            
                                            
                                            
                                           
                                        </ul>
                                    </section>
                                </div>
            
                            </div>


                        </div>
                    </main>
                </div>
            </div>

        </div>
    </div>
    </>
  );

  
}