import React,{useState,useCallback,useEffect} from 'react';
import {NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json';

export default function Tests() {

    let [responseData, setResponseData] = useState([]);
    const[loading,setLoading]       =   useState(false);
    const id                        = sessionStorage.getItem('service_id');
    const location_id               = sessionStorage.getItem('location_id');
    const API_URL                   = config.API_URL; 
    const modify                    = sessionStorage.getItem('modify');

   
   console.log('service id from dob-->'+id);

   if(modify)
   {
       console.log('modify is true',modify);
   }
    const fetchData = useCallback(() => {

        setLoading(true);
        var url=API_URL+"/categoryservices";
        axios.get(url)
        // .then(onSuccess)
        // .catch(onFailure);
       // .then(request => request.data)
        //.catch(onFailure);
      .then((response) => {

 

        //response.data.data.map(home => console.log(home))
        console.log(response.data);
        if(response.data.success)
        {
            console.log('here');
            setResponseData(response.data.data);
            setLoading(false);
        }
      

       
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
      })
    }, []);
    useEffect(() => {
      fetchData()
      
    }, [fetchData])
    
   

  return(
      <>
       <div>
        <div className="viewport">
           
            <div id="main" className="content">
                <div>
                    <main className="page wrap" tabIndex="-1">
                        <div className="box box--md box--solo">
                        <NavLink className="back box__back" to="/verifyinfo">Back to Verify Info</NavLink>
                        
                            <h1 className="box__title">Driver Knowledge Test</h1>
                           
                            <div className="box__content">
                                <p>According to the information you have provided.You are eligible to take the following exam.  </p>
                                
                            </div> 
                                
                            <ul className="result-list">
                            {loading ? <div><img src={config.BASE_URL+"/img/loader.gif"} alt="loading.."/></div>:''}
                            {
                                responseData.map((item,index)=>{
                                    let url ="/zipcode?id="+item.service_id+"&price="+item.service_price;
                                    let link ="/appointment/"+item.service_id+"/"+location_id;
                                    console.log(item.service_id,parseInt(id));
                                    if(item.service_id===parseInt(id))
                                    {
                                        return( 
                                        <li key={item.service_id}>
                                            <article className="media media--alt">
                                                <div className="media__caption">
                                                    <h2 className="media__title">{item.service_name}</h2>
                                                    <p className="media__summary">The cost of this test is $ {item.service_price}</p>
                                                    <p className="media__summary">{item.service_description}</p>
                                                </div>
                                                <div className="media__content">
                                                    <NavLink className="btn" to={link}>Schedule</NavLink>
                                                </div>
                                            </article>
                                        </li>);
                                    }
                               
                                })
                            }
                       
                           
                               
                            </ul>
                                   
                               
                                
                           
                        </div>
                    </main>
                </div>
            </div>

        </div>
    </div>
    
    
    </>
  );
}