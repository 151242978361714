import React,{useState,useCallback,useEffect} from 'react';
import {useHistory,NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json';

export default function VerifyInfo() {

    //let [responseData, setResponseData] = useState([]);
    const [firstname, setFirstname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastname, setLastname] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [readonly, setReadonly] = useState(false);
    //const [errmsg, setErrmsg] = useState();
    const [msg, setMsg] = useState('');
    let history = useHistory();
    const API_URL = config.API_URL;

    const tokenString = sessionStorage.getItem('token');
    const location_id = sessionStorage.getItem('location_id'); // location

    var bkurl         = "/register/"+location_id; 
    const userToken   = JSON.parse(tokenString);
    const customer_id = userToken?.token;

    
  

    const fetchData = useCallback(() => {

     
      var url=API_URL+"/newaccount/customerinfo?customer_id="+customer_id;
        axios.get(url)
        // .then(onSuccess)
        // .catch(onFailure);
       // .then(request => request.data)
        //.catch(onFailure);
      .then((response) => {

 

        //response.data.data.map(home => console.log(home))
        console.log(response.data);
        if(response.data.success)
        {
            console.log('here');
            let results=response.data.data;
           // setResponseData(results);
            //console.log(results);


            var d = new Date(results.date_of_birth),
            dmonth = '' + (d.getMonth() + 1),
            dday = '' + d.getDate(),
            dyear = d.getFullYear();

            console.log(dday,dmonth,dyear);

            sessionStorage.setItem('firstname',results.first_name); // save firstname
            sessionStorage.setItem('lastname',results.last_name); // save lastname

            setFirstname(results.first_name);
            setMiddlename(results.middle_name);
            setLastname(results.last_name);
            setMonth(dmonth);
            setDay(dday);
            setYear(dyear);
            setEmail(results.email);
            setPhone(results.cell_phone);
           
            
          
           
        }
      

       
      })
      .catch((error) => {
        console.log(error)
      })
    }, []);
    
    useEffect(() => {
      fetchData()
      
    }, [fetchData])


    const onFailure = error => {
        console.log('---------------Error---------');
        console.log('inside onfailure',error);  
      
      };
    
    
     
    
      
      async function UpdatCustomer(credentials) {
      
           console.log(credentials);
        
        var url =API_URL+'/newaccount/updatepersonalinfo';
        //const response  = await APIKit.get(endpoint);
        //  console.log(response)
        // const data = await JSON.parse(response.request._response);
        return axios.post(url, credentials)
       // .then(onSuccess)
       // .catch(onFailure);
       .then(request => request.data)
       .catch(onFailure);
        
      }


    const handleSubmit = async e => {
        e.preventDefault();
    
       
    
        console.log('handle sumbit');
        console.log(firstname,middlename,lastname,month,day,year,email);
    
      
        const results = await 
        UpdatCustomer({
          'email':email,
          'number':phone,
          'customer_id':customer_id,
          'first_name':firstname,
          'last_name':lastname,
          'dob':""+year+"-"+month+"-"+day+""
    
        });
    
      if(results){

        console.log(results);
        console.log(results.message);
        if(results.success)
        {
          setMsg(results.message);
          var url="/consent";
          history.push(url);
        }else{
          setMsg(results.message);
        }
      
       
        
       
      }
    
       
      }

      const back = ()=>{
        console.log('back');
        history.goBack();
      }



  return(
   
     <div>
        <div className="viewport">
           
        
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done" tabIndex="-1" aria-labelledby="page-title">
                        <div className="box box--md box--solo">
                        <NavLink className="back box__back" to={bkurl}>Back to Registration</NavLink>
                           
                        
                            <h1 className="box__title">Verify Your Information </h1>


                            <div className="table-responsive exam-dd">
                                <table className="table">
                                    
                                    <tbody>
                                        <tr key="0">
                                            <td >First name</td>
                                            <td>{firstname}</td>
                                        </tr>
                                        <tr key="1">
                                            <td>Middle Name</td>
                                            <td>{middlename}</td>
                                        </tr>
                                        <tr key="2">
                                            <td>Last Name</td>
                                            <td>{lastname}</td>
                                        </tr>
                                        <tr key="3">
                                            <td>Date of Birth</td>
                                            <td>{month+"/"+day+"/"+year}</td>
                                        </tr>
                                        <tr key="4">
                                            <td>Email</td>
                                            <td>{email}</td>
                                        </tr>
                                        <tr key="5">
                                            <td>Phone</td>
                                            <td>{phone}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p></p>
                          
                            <p>Please verify your information. If you need to make a change, please  <NavLink to={bkurl}>Click Here To Go Back</NavLink> or If the above information is correct, then please click Save &amp; Continue</p>
<NavLink className="btn" to="/tests">Save & Continue</NavLink>
                            
                            <div>{msg}</div>
                        </div>

                        
                       
                    </main>
                   
                </div>
            </div>

        </div>
    </div>
    
  );
}