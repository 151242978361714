import React,{useState,useCallback,useEffect} from 'react';
//import {NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';

export default function Dashboard() {
  const [responseData, setResponseData] = useState([]);
  const [msg,setMsg] = useState('');
  //const [candonew,setCandonew] = useState(false);
  const[loading,setLoading] = useState(false);
  //const [date,setDate] = useState();
  //const [time,setTime] = useState();
  //const [price,setPrice] = useState();
  //const [status,setStatus] = useState();
  //const [location,setLocation] = useState();
  //const [exam,setExam] = useState();

  const tokenString = sessionStorage.getItem('token');
  const userToken   = JSON.parse(tokenString);
  const customer_id = userToken?.token;


  const modify = sessionStorage.getItem('modify');

  const API_URL = config.API_URL; 
 
  const fetchData = useCallback(() => {

    setLoading(true);  
    var url=API_URL+"/home/dashboardall";
      axios.post(url,{"customer_id":customer_id})
      // .then(onSuccess)
      // .catch(onFailure);
     // .then(request => request.data)
      //.catch(onFailure);
    .then((response) => {


      let results = response.data;
      //response.data.data.map(home => console.log(home))
      console.log(results);
      if(results.success)
      {
          console.log('here');
         setResponseData(results.data.all);
        // setCandonew(results.data.canDoNew);
         setMsg('');
       //  setDate(fulldate(results.data.appointment.appointment_date));
        // setTime(results.data.appointment.appointment_start_time+" - "+results.data.appointment.appointment_end_time);
         //setPrice(results.data.service.service_price);
         //setStatus(results.data.appointment.appointment_status);
         //setLocation(results.data.location.location_name);
         //setExam(results.data.service.service_name);
        
      }else{
        console.log('sdfds');    
        setMsg(results.message);
      }
    
      setLoading(false);
     
    })
    .catch((error) => {
      setLoading(false);
      console.log(error)
    })
  }, []);
  useEffect(() => {
    fetchData()
    
  }, [fetchData]);

 
/*

  async function cancel(app_id) {
    
    const r =window.confirm("Do you want to Cancel your Appointment?"); 
    if(r === false){ console.log('ok'); return; }

   
   
    let postData =  {     
                       "appointment_id":app_id ,
                    };

        
        // const appointment = JSON.stringify(postData);
        
        // console.log('book func',appointment);

        var response = await cancelSlot(postData);
       // console.log(response);
        if(response)
        {
           
            if(response.success)
            {
               console.log('success');
               setMsg(response.message); 
               fetchData();
              

               
            }else{

              setMsg(response.message); 
              // setClassname('alert-error');
               // setError(response.message);

            }
        }
       
   
    
    
  }
  */

  /*
async function cancelSlot(postData) {

     
    var url =API_URL+'/booking/bookcancel';
          
    return axios.post(url, postData)
    .then(request => request.data)
    .catch(onFailure);

   
    
}
*/

const onFailure = error => {
  console.log('---------------Error---------');
  console.log('inside onfailure',error);  
 



};

  function fulldate(dstr){
        
    var date =new Date(dstr);
    var month = date.toLocaleString('en-us', { month: 'long' });
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var dayName = days[date.getDay()];
    return dayName +', ' + month +' '+date.getDate().toString(); 
    
  //  var year = date.getFullYear();

    //var month = (1 + date.getMonth()).toString();
    //month = month.length > 1 ? month : '0' + month;
  
    //var day = date.getDate().toString();
    //day = day.length > 1 ? day : '0' + day;
    
    //return month + '/' + day + '/' + year;

}

  return(
    <div>
  
    
    <div className="viewport">
        
        <div id="main" className="content">
            <div>
                <main className="page wrap animation--fade-enter-done" tabIndex="-1" aria-labelledby="page-title">
                <div className="box box--lg box--solo" style={{marginTop:10}}>
                              <div className="card-box">
                              <div className="left"><h2>Appointments</h2></div>
                              
                             
                              
                                          
                                         
                        <div className="table-responsive exam-dd">
                        {loading ? <img src={config.BASE_URL+"/img/loader.gif"} alt="loading.."/>:''}   
                        <table className="table">
                            <thead>
                                <tr key="0">
                                    <th scope="col">Exam Type</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Location</th>
                                    {modify ?  <th scope="col">Action</th>:null}     
                                   
                                </tr>
                            </thead>
                            <tbody>
                   


                 
                                              
                    {  
                        responseData.map((item,index)=>{
                        
                        if(item.payment_status==="Unpaid")
                        {return null;}
                            return( 
                              
                                <tr key={index}>
                                    <td>{item.service_name}</td>
                                    <td>{fulldate(item.appointment_date)}</td>
                                    <td>{item.appointment_start_time+" - "+item.appointment_end_time}</td>
                                    <td>$ {item.payment_amount}</td>
                                    <td>{item.appointment_status}</td>
                                    <td>{item.location_name}</td>
                                    {(modify==='1' && item.appointment_status==="As Scheduled") ? <td><a style={{margin:5}} className="callout__btn btn btn--outline" href={"/modifyappointment/"+item.service_id+"/"+item.location_id+"/"+item.appointment_id+"/"+fulldate(item.appointment_date)+"/"+item.appointment_start_time+" - "+item.appointment_end_time}>Modify appointment</a></td>:null}     
                                   
                                </tr>


                            );
                        })
                    }

                    
                    </tbody>
                        </table>
                    </div>
                                        
                                      
                                      
                                     
                                     
                                      </div>
                                      </div>

     
                  {msg!==''?<div className="box box--md box--solo" style={{marginTop:10}}><div className="card-box"><h4>{msg}</h4></div></div>:null}
                 

                 


                        
                   
                </main>
            </div>
        </div>
    </div>
</div>
  );
}