import React from 'react';
import {NavLink } from 'react-router-dom';



export default function Success() {



    const location   = sessionStorage.getItem('appointment_location');
    const firstname  = sessionStorage.getItem('firstname'); 
    const lastname  = sessionStorage.getItem('lastname');
    const modify = sessionStorage.getItem('modify');

    
  
  return(
   
        <div>
        <div className="viewport">
           
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done"  aria-labelledby="page-title">
                        <div className="box box--md box--solo">
                            
                            <div className="success-box"><img src="img/passed-icon.svg" alt="" width="70"/>
                           
                            </div>
                            <p> </p>
                            <p>Thank you!</p>
                            {modify ?  null:<p className="capitalize">{firstname} {lastname}</p>}   
                            {modify ?  <p>You have successfully modified your appointment.</p>:<p>Your payment is processed and your test has been scheduled successfully at {location}.</p>}   
                            
                            <NavLink className="callout__btn btn btn--outline" to="/dashboard">View Scheduled Test</NavLink>
                            
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
    
  );
}