import React,{useEffect} from 'react';


export default function Logout() {

  
    
  useEffect(() => {

    const back = async ()=>{
     console.log('logout');
     await sessionStorage.removeItem('token');

     window.location.href = '/login';
    
   }
  
  back();
 
   
  }, []);
   

  



 return (
    <div></div>
  );


}