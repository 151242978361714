import React, { useState } from 'react';
import axios from 'axios';
import { NavLink,useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../config.json';
import { useParams } from "react-router";



export default function Lookup({ setToken }) {
  
  const [firstname, setFirstname] = useState();
  const [middlename, setMiddlename] = useState();
  const [lastname, setLastname] = useState();
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const [year, setYear] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [classname, setClassname] = useState();
  const [loading,setLoading] = useState(false);
  let history = useHistory();

  const API_URL = config.API_URL;   
  //const { location_id } = props.match.params;
  let { location_id } = useParams();
  console.log('---',location_id);

  function is21orOlder(dateString) {
    const dob = new Date(dateString);
    const dobPlus21 = new Date(dob.getFullYear() + 21, dob.getMonth(), dob.getDate());
    
    return dobPlus21.valueOf() <= Date.now();
  }
  
 


  const onFailure = error => {
    console.log('---------------Error---------');
    console.log('inside onfailure',error);  
    setClassname('alert-error');
    setError("Something went wrong");
    setLoading(false);
  
  };


 

  
  async function loginUser1(credentials) {
  
       console.log(credentials);
    
    var url =API_URL+'/newaccount/loginweb';
    //const response  = await APIKit.get(endpoint);
    //  console.log(response)
    // const data = await JSON.parse(response.request._response);
    return axios.post(url, credentials)
   // .then(onSuccess)
   // .catch(onFailure);
   .then(request => request.data)
   .catch(onFailure);
    
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (password !== confirmpassword) {
      setClassname('alert-error');
      setError("Passwords don't match");
      
      return;
  } 

    console.log('handle sumbit');
    setClassname();
    setError();
    setLoading(true);
    
    //console.log(firstname,middlename,lastname,month,day,year,email,password,confirmpassword,code);

  
    const results = await 
    loginUser1({
      'email':email,
      'password':password,
      'number':phone,
      'country_dial_code':code,
      'country_code':code,
      'first':firstname,
      'last':lastname,
      'dob':""+year+"-"+month+"-"+day+""

    });

  
    
   if(results){

    console.log(results);
    console.log(results.message);
        if(results.success)
        {
            setClassname('alert-success');
            setError(results.message);
        

            // Testing:
            if(is21orOlder(results.data.date_of_birth))
            {
                await sessionStorage.setItem('service_id', 46); // NCDL- Standard
                await sessionStorage.setItem('location_id', location_id); // location
            }else{
                await sessionStorage.setItem('service_id', 47); // NCDL - GRAD
                await sessionStorage.setItem('location_id', location_id); // location
            }
            
            await sessionStorage.setItem('modify', 1); // modify appointment
          //  history.push('/modifyappointment');
           
            await setToken({token:results.data.customer_id});
            history.push('/dashboard');
          
            
        
        }else{

            setClassname('alert-error');
            setError(results.message);
        
        }
    }
   setLoading(false);
   
   // setToken(token);

   
  }

 

  return(
    <div>
        <div className="viewport">
            
        
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done"  aria-labelledby="page-title">
                        <div className="box box--md box--solo"><NavLink className="back box__back" to="/">Back to Home</NavLink>
                            <h1 className="box__title">Modify an Appointment</h1>
                            <form className="form" onSubmit={handleSubmit}>
                                <ul className="form__fields">
                                   
                                    
                                    <li className="field--half m-15 p-2"><label>Date of birth</label>
                                        <div className="date-select">
                                            <div><span className="meta" >Maximum date is Mon May 03 2021 16:04:19 GMT+0500 (Pakistan Standard Time)</span>
                                            <label className="select"><span className="meta">Month</span>
                                            <select required onChange={e => setMonth(e.target.value)}>
                                                        <option value="">Month</option>
                                                        <option value="1">January</option>
                                                        <option value="2">February</option>
                                                        <option value="3">March</option>
                                                        <option value="4">April</option>
                                                        <option value="5">May</option>
                                                        <option value="6">June</option>
                                                        <option value="7">July</option>
                                                        <option value="8">August</option>
                                                        <option value="9">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </select></label><label className="select"><span className="meta">Day</span><select required onChange={e => setDay(e.target.value)}>
                                                        <option value="">Day</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                    </select></label><label className="select"><span className="meta">Year</span><select required onChange={e => setYear(e.target.value)}>
                                                        <option value="">Year</option>
                                                        <option value="2005">2005</option>
                                                        <option value="2004">2004</option>
                                                        <option value="2003">2003</option>
                                                        <option value="2002">2002</option>
                                                        <option value="2001">2001</option>
                                                        <option value="2000">2000</option>
                                                        <option value="1999">1999</option>
                                                        <option value="1998">1998</option>
                                                        <option value="1997">1997</option>
                                                        <option value="1996">1996</option>
                                                        <option value="1995">1995</option>
                                                        <option value="1994">1994</option>
                                                        <option value="1993">1993</option>
                                                        <option value="1992">1992</option>
                                                        <option value="1991">1991</option>
                                                        <option value="1990">1990</option>
                                                        <option value="1989">1989</option>
                                                        <option value="1988">1988</option>
                                                        <option value="1987">1987</option>
                                                        <option value="1986">1986</option>
                                                        <option value="1985">1985</option>
                                                        <option value="1984">1984</option>
                                                        <option value="1983">1983</option>
                                                        <option value="1982">1982</option>
                                                        <option value="1981">1981</option>
                                                        <option value="1980">1980</option>
                                                        <option value="1979">1979</option>
                                                        <option value="1978">1978</option>
                                                        <option value="1977">1977</option>
                                                        <option value="1976">1976</option>
                                                        <option value="1975">1975</option>
                                                        <option value="1974">1974</option>
                                                        <option value="1973">1973</option>
                                                        <option value="1972">1972</option>
                                                        <option value="1971">1971</option>
                                                        <option value="1970">1970</option>
                                                        <option value="1969">1969</option>
                                                        <option value="1968">1968</option>
                                                        <option value="1967">1967</option>
                                                        <option value="1966">1966</option>
                                                        <option value="1965">1965</option>
                                                        <option value="1964">1964</option>
                                                        <option value="1963">1963</option>
                                                        <option value="1962">1962</option>
                                                        <option value="1961">1961</option>
                                                        <option value="1960">1960</option>
                                                        <option value="1959">1959</option>
                                                        <option value="1958">1958</option>
                                                        <option value="1957">1957</option>
                                                        <option value="1956">1956</option>
                                                        <option value="1955">1955</option>
                                                        <option value="1954">1954</option>
                                                        <option value="1953">1953</option>
                                                        <option value="1952">1952</option>
                                                        <option value="1951">1951</option>
                                                        <option value="1950">1950</option>
                                                        <option value="1949">1949</option>
                                                        <option value="1948">1948</option>
                                                        <option value="1947">1947</option>
                                                        <option value="1946">1946</option>
                                                        <option value="1945">1945</option>
                                                        <option value="1944">1944</option>
                                                        <option value="1943">1943</option>
                                                        <option value="1942">1942</option>
                                                        <option value="1941">1941</option>
                                                        <option value="1940">1940</option>
                                                        <option value="1939">1939</option>
                                                        <option value="1938">1938</option>
                                                        <option value="1937">1937</option>
                                                        <option value="1936">1936</option>
                                                        <option value="1935">1935</option>
                                                        <option value="1934">1934</option>
                                                        <option value="1933">1933</option>
                                                        <option value="1932">1932</option>
                                                        <option value="1931">1931</option>
                                                        <option value="1930">1930</option>
                                                        <option value="1929">1929</option>
                                                        <option value="1928">1928</option>
                                                        <option value="1927">1927</option>
                                                        <option value="1926">1926</option>
                                                        <option value="1925">1925</option>
                                                        <option value="1924">1924</option>
                                                        <option value="1923">1923</option>
                                                        <option value="1922">1922</option>
                                                        <option value="1921">1921</option>
                                                        <option value="1920">1920</option>
                                                        <option value="1919">1919</option>
                                                        <option value="1918">1918</option>
                                                        <option value="1917">1917</option>
                                                        <option value="1916">1916</option>
                                                        <option value="1915">1915</option>
                                                        <option value="1914">1914</option>
                                                        <option value="1913">1913</option>
                                                        <option value="1912">1912</option>
                                                        <option value="1911">1911</option>
                                                        <option value="1910">1910</option>
                                                        <option value="1909">1909</option>
                                                        <option value="1908">1908</option>
                                                        <option value="1907">1907</option>
                                                    </select></label></div>
                                        </div>
                                    </li>
                                    <li><label htmlFor="user-email">Email address</label><input id="user-email" maxLength="50" type="email" required data-errormsg="Please enter a valid email address" onChange={e => setEmail(e.target.value)} /></li>
                                    <li className="field--multi"><label htmlFor="user-phone">Phone number</label>
                                    <label className="select" htmlFor="user-country-code">
                                    <select id="user-country-code" required onChange={e => setCode(e.target.value)}  data-errormsg="Please enter country code">
                                    <option value="">Country code</option>
                                                <option value="1">USA (+1)</option>
                                            
                                            </select></label><input id="user-phone" type="tel" maxLength="10" onChange={e => setPhone(e.target.value)} required data-errormsg="Please enter a valid phone number" title="10 digit telephone number with no dashes or dots" data-country="US" pattern="[1-9]{1}[0-9]{9}" /></li>
                                            
                                           {error ? <li className={classname}><label className="form__lbl" htmlFor="geolocate-zip">{error}</label></li>:''}
                                </ul>

                                {loading ? <div><img src={config.BASE_URL+"/img/loader.gif"} alt="loading.."/></div>:<button className="btn" type="submit">Continue</button>}
                                
                                
                                
                            </form>
                        </div>
                    </main>
                </div>
            </div>

        </div>
    </div>

  )
}