import React from 'react';
import {NavLink } from 'react-router-dom';


export default function Start() {

   

  return(
      <>
    
     <div>
     <div className="viewport">
            
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done">
                        <div className="box box--md box--solo">
                            <h1 id="page-title" className="box__title box__title--alt">Driver Knowledge Test Appointment Scheduling</h1>
                            <div className="box__content">
                                <p>Welcome to the SignUp online appointment system for the  DMV Driver Knowledge Test. SignUp has partnered with the Department of Motor Vehicles to offer the Driver Knowledge Test at a proxy testing location and it is available by appointment only.</p>
                                <p>Get started by choosing an Option below!</p>
                            </div>
                           
                           <div className="col-5">
                           <aside className="callout">
                                <h2 className="callout__title">Need to make an Appointment?</h2>
                                <p>If you want to make a new appointment, you can do so here.</p><NavLink className="callout__btn btn btn--outline" to="/locations">Schedule Appointment</NavLink>
                            </aside>
                               
                           </div>

                           <div className="col-5">
                            
                           <aside className="callout">
                                <h2 className="callout__title" style={{minHeight: "38px"}}>Need to make a change?</h2>
                                <p>If you already have an appointment and need to reschedule or cancel it, you can do so here.</p><NavLink className="callout__btn btn btn--outline" to="/lookup">Modify appointment</NavLink>
                            </aside>
                               
                            </div>

                            <span className="clearfix"></span>

                           
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}