import React,{useState,useCallback,useEffect} from 'react';
import {NavLink,Redirect,useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../config.json';


export default function Locations(props) {

   // const id1 = new URLSearchParams(window.location.search).get('id');
    //const zipcode1 = new URLSearchParams(window.location.search).get('zipcode');
    //const price1 = new URLSearchParams(window.location.search).get('price');

    const { id } = props.match.params;
    const { zipcode } = props.match.params;
    const { price } = props.match.params;
  
    let [responseData, setResponseData] = useState([]);
    const[loading,setLoading] = useState(false);
    const history = useHistory();

    const API_URL = config.API_URL; 

   
    const fetchData = useCallback(() => {

        console.log('fetch data');
        let id=46;
        let zipcode=100;
        setLoading(true);
       // var url=API_URL+"/categoryservices/locations";
        var url=API_URL+"/service/weblocations";
         
        axios.post(url)
        // .then(onSuccess)
        // .catch(onFailure);
       // .then(request => request.data)
        //.catch(onFailure);
      .then((response) => {

 

        //response.data.data.map(home => console.log(home))
        console.log(response.data);
        if(response.data.success)
        {
            console.log('here');
            setResponseData(response.data.data);
            setLoading(false);
        }
      

       
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
    }, []);
    
    useEffect(() => {
      fetchData()
      
    }, [])
    
    if ((id==null || id==="" || id==='0')|| (zipcode==null || zipcode==="" || zipcode==='0')) {
     //  return <Redirect to="/" />
     }

     const back = ()=>{
        console.log('sfsdf');
        history.goBack();
      }

  return(
    <>
     <div>
        <div className="viewport">
           
            <div id="main" className="content">
                <div>
                    <main className="page wrap animation--fade-enter-done" tabIndex="-1" aria-labelledby="page-title">
                        <div className="box box--md box--solo">
                        <NavLink className="back box__back" to="/">Back to Home</NavLink>
                        
                        
                       
                            <h1 className="box__title">Choose a Location</h1>
                            <ol className="result-list">
                            
                            {loading ? <div><img src={config.BASE_URL+"/img/loader.gif"} alt="loading.."/></div>:''}
                            {
                                responseData.map((item,index)=>{
                                   // let url ="/appointment/"+item.service_id+"/"+item.location_id;
                                   let url ="/register/"+item.location_id;

                                    let google_url='https://maps.google.com/?q='+item.lat+','+item.lng;

                                return( 
                                    <li key={item.location_id}>
                                    <article className="location ">
                                        <div className="location__adr media">
                                            <section className="media__caption">
                                                <h2 id="location-PLc6d2f5786025428fbf750af179026601" className="location__title">{item.location_name}</h2>
                                                <p className="adr"><span className="adr__street">{item.location_description}</span><span className="adr__street">{item.location_address}</span></p><span className="location__parking"><span aria-describedby="parking-info-PLc6d2f5786025428fbf750af179026601">Parking Information</span>
                                                    <div className="tooltip"><span className="tooltip__label" role="presentation" aria-hidden="true">(?)</span>
                                                        <div id="parking-info-PLc6d2f5786025428fbf750af179026601" className="tooltip__body" role="tooltip">Street parking</div>
                                                    </div>
                                                </span>
                                            </section><a className="location__map media__content" href={google_url} target="_blank" rel="noopener noreferrer"><img src="https://maps.googleapis.com/maps/api/staticmap?center=38.9032103,-77.0437848&amp;zoom=15&amp;scale=2&amp;size=150x150&amp;maptype=roadmap&amp;key=AIzaSyBXUGDThYYVJOIcfKhKiUCbkQJz8LDVvXg&amp;format=png&amp;visual_refresh=true&amp;markers=38.9032103,-77.0437848" width="150" height="150" alt="A map of College Information Center’s location"/></a>
                                        </div>
                                        <div className="location__action"><NavLink className="btn" to={url}>Continue</NavLink></div>
                                        <div className="location__detail">
                                            <section className="location__section">
                                                <h3 className="meta">Features</h3>
                                                <ul className="location__features">
                                                    <li><svg aria-hidden="true" className="icn icn--wheelchair" xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" aria-labelledby="icn-wheelchair-title">
                                                            <title id="icn-wheelchair-title">Wheelchair</title>
                                                            <path d="M10.9714 7.39048L7.84762 7.54286L9.6 5.56191C9.75238 5.33333 9.82857 4.95238 9.75238 4.57143C9.67619 4.34286 9.6 4.11429 9.37143 3.9619L5.25714 1.52381C4.95238 1.29524 4.49524 1.37143 4.19048 1.6L2.13333 3.50476C1.75238 3.88571 1.67619 4.41905 2.05714 4.8C2.3619 5.18095 2.97143 5.18095 3.35238 4.87619L4.87619 3.50476L6.32381 4.34286L3.12381 7.61905C3.04762 7.69524 3.04762 7.77143 2.97143 7.77143C2.59048 7.92381 2.20952 8.07619 1.90476 8.30476L3.04762 9.44762C3.42857 9.29524 3.80952 9.14286 4.19048 9.14286C5.6381 9.14286 6.85714 10.3619 6.85714 11.8095C6.85714 12.2667 6.78095 12.6476 6.55238 12.9524L7.69524 14.0952C8.15238 13.4095 8.38095 12.6476 8.38095 11.8095C8.38095 10.8952 8.07619 9.98095 7.54286 9.29524L10.0571 9.06667L9.90476 12.7238C9.82857 13.2571 10.2095 13.6381 10.7429 13.7143H10.819C11.2762 13.7143 11.6571 13.3333 11.7333 12.8762L11.8857 8.38095C11.8857 8.15238 11.8095 7.84762 11.6571 7.69524C11.4286 7.46667 11.2 7.39048 10.9714 7.39048ZM10.6667 3.04762C11.0708 3.04762 11.4584 2.88708 11.7442 2.60131C12.0299 2.31554 12.1905 1.92795 12.1905 1.52381C12.1905 1.11967 12.0299 0.732083 11.7442 0.446313C11.4584 0.160544 11.0708 0 10.6667 0C10.2625 0 9.87494 0.160544 9.58917 0.446313C9.3034 0.732083 9.14286 1.11967 9.14286 1.52381C9.14286 1.92795 9.3034 2.31554 9.58917 2.60131C9.87494 2.88708 10.2625 3.04762 10.6667 3.04762ZM6.47619 15.3143C5.79048 15.7714 5.02857 16 4.19048 16C1.90476 16 0 14.0952 0 11.8095C0 10.9714 0.228571 10.2095 0.685714 9.52381L1.82857 10.6667C1.67619 11.0476 1.52381 11.4286 1.52381 11.8095C1.52381 13.2571 2.74286 14.4762 4.19048 14.4762C4.64762 14.4762 5.02857 14.4 5.33333 14.1714L6.47619 15.3143Z"></path>
                                                        </svg>{item.wheelchair ? 'Wheelchair Ramp' : ''}</li>
                                                    <li><svg aria-hidden="true" className="icn icn--elevator" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-elevator-title">
                                                            <title id="icn-elevator-title">Elevator</title>
                                                            <path d="M3.55556 0L6.71605 3.2H4.34568V6.4H2.76543V3.2H0.395062L3.55556 0ZM11.4568 6.4L8.2963 3.2H10.6667V0H12.2469V3.2H14.6173L11.4568 6.4ZM1.58025 8H14.4198C14.8389 8 15.2408 8.16857 15.5372 8.46863C15.8335 8.76869 16 9.17565 16 9.6V14.4C16 14.8243 15.8335 15.2313 15.5372 15.5314C15.2408 15.8314 14.8389 16 14.4198 16H1.58025C1.16114 16 0.759197 15.8314 0.462844 15.5314C0.16649 15.2313 0 14.8243 0 14.4V9.6C0 9.17565 0.16649 8.76869 0.462844 8.46863C0.759197 8.16857 1.16114 8 1.58025 8ZM1.58025 9.6V14.4H14.4198V9.6H1.58025Z"></path>
                                                        </svg>{item.elevator ? 'Elevator' : ''}</li>
                                                </ul>
                                            </section>
                                        </div>
                                    </article>
                                </li>
                               
                               );
                               
                                })
                            }
                               
                               
                            </ol>
                        </div>
                    </main>
                </div>
            </div>

        </div>
    </div>
    </>
  );
}