import React from 'react';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';

import './App.css';

//import Login from './components/Login/Login';
//import Preferences from './components/Preferences/Preferences';
import Tests from './components/Tests';
//import Zipcode from './components/Zipcode';
import Locations from './components/Locations';
import Appointment from './components/Appointment';
//import Info from './components/Info';
import Consent from './components/Consent';
import useToken from './useToken';
import Register from './components/Register';
import Payment from './components/Payment';
import Logout from './components/Logout';
import Start from './components/Start';
import Lookup from './components/Lookup';
import VerifyInfo from './components/VerifyInfo';
//import Testing from './components/Testing';


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Success from './components/Success';
import config from './config.json';
import Dashboard from './components/Dashboard/Dashboard';
//import { CSSTransition,SwitchTransition } from 'react-transition-group';
import ModifyAppointment from './components/ModifyAppointment';

/**********Sandbox key *******/
const promise = loadStripe("pk_test_51IONUKFTDR41AqmjEMiH9YJ6zOkFy241GcNYyQJ2TeqvuMDcaUdMLZG5EoqPEeE9nRDocPiJirmfzY5cro7hqxrf00jKzvFo3W");
/*******************/
//const promise = loadStripe("pk_live_51IONUKFTDR41AqmjJdi8AmzJYP2ibXg7uyxRUQmAFeRy38ycmraVKZ9DD79wbz24Ppltf06QbV5IOkqL29hJ9sG600p7kLMh0S");



function App() {
  
  const { token, setToken } = useToken();
  const BASE_URL=config.BASE_URL;
  const logo = BASE_URL+"/img/dc.png";


  if(!token) {
   // return <Login setToken={setToken} />
    // return <Register />

    return(

      <div className="wrapper">
      <header id="header" className="site-head">
          <h1 className="meta">DC.gov</h1><a className="brand" href="/"><img className="brand__logo" src={logo} alt="The DC.gov logo" /></a>
      </header> 
        <BrowserRouter>
          <Switch>
          <Route exact  path="/" >
            <Start/>
          </Route>
           
            <Route exact path="/register/:location_id" >
              <Register setToken={setToken}  />
            </Route>
            <Route exact path="/lookup" >
              <Lookup setToken={setToken}  />
            </Route>

            <Route path="/verifyinfo">
            <VerifyInfo/>
          </Route>
          <Route path="/dashboard">
            <Dashboard/>
          </Route>

          <Route path="/modifyappointment/:service/:location_id/:app_id/:app_date/:app_time" render={(props) => <ModifyAppointment {...props} />}/>
        
            <Route path="/locations" render={(props) => <Locations  {...props} />}/>
            <Route path="/404" component={Start} /> 
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
        </div>
     
    )
  }

  return (
    <div className="wrapper">
      <header id="header" className="site-head">
          <h1 className="meta">DC.gov</h1><a className="brand" href="/"><img className="brand__logo" src={logo} alt="The DC.gov logo" /></a>
      </header>
     
     

      
      <BrowserRouter>
    
        <Switch>
        <Route exact  path="/" >
            <Start/>
          </Route>
          <Route exact path="/register/:location_id" >
              <Register setToken={setToken}  />
            </Route>
          <Route path="/verifyinfo">
            <VerifyInfo/>
          </Route> 
        <Route exact  path="/tests" >
            <Tests />
          </Route>
          <Route path="/locations" render={(props) => <Locations  {...props} />}/>

          <Route exact path="/lookup" >
              <Lookup setToken={setToken}  />
            </Route>
         
            <Route path="/dashboard">
            <Dashboard/>
          </Route>
          <Route path="/locations" render={(props) => <Locations  {...props} />}/>  
          <Route path="/appointment/:service/:location_id" render={(props) => <Appointment {...props} />}/>
          <Route path="/modifyappointment/:service/:location_id/:app_id/:app_date/:app_time" render={(props) => <ModifyAppointment {...props} />}/>
         
          <Route path="/consent">
            <Consent />
          </Route>
          <Route path="/payment">
          
          <Elements stripe={promise}>
            <Payment />
          </Elements>
           
            </Route>
            
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
         
          
          <Route path="/404" component={Tests} /> 
          <Redirect to="/tests" />
         
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;